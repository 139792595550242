// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Gallery.css */
.gallery {
  padding: 40px 20px;
  background-color: #FFFFFF;
  text-align: center;
}

.gallery h2 {
  font-size: 30px;
  color: #333333;
  margin-bottom: 20px;
}

.gallery-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.gallery-image {
  width: 100%;
  height: auto;
  border-radius: 8px;
  transition: transform 0.3s;
  border: 2px solid #D3D3D3; /* Border added with light gray */
}

.gallery-image:hover {
  transform: scale(1.05);
  border-color: #E5D3C3; /* Border changes to tan on hover */
}

`, "",{"version":3,"sources":["webpack://./src/components/Gallery.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,4DAA4D;EAC5D,SAAS;AACX;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,0BAA0B;EAC1B,yBAAyB,EAAE,iCAAiC;AAC9D;;AAEA;EACE,sBAAsB;EACtB,qBAAqB,EAAE,mCAAmC;AAC5D","sourcesContent":["/* Gallery.css */\n.gallery {\n  padding: 40px 20px;\n  background-color: #FFFFFF;\n  text-align: center;\n}\n\n.gallery h2 {\n  font-size: 30px;\n  color: #333333;\n  margin-bottom: 20px;\n}\n\n.gallery-grid {\n  display: grid;\n  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));\n  gap: 15px;\n}\n\n.gallery-image {\n  width: 100%;\n  height: auto;\n  border-radius: 8px;\n  transition: transform 0.3s;\n  border: 2px solid #D3D3D3; /* Border added with light gray */\n}\n\n.gallery-image:hover {\n  transform: scale(1.05);\n  border-color: #E5D3C3; /* Border changes to tan on hover */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
