// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Header.css */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #333333;
  color: #F5F5F5;
}

.logo {
  font-size: 24px;
  font-weight: bold;
  color: #F5F5F5;
}

.nav-links {
  list-style-type: none;
  display: flex;
  gap: 15px;
}

.nav-links li a {
  color: #D3D3D3;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s;
}

.nav-links li a:hover {
  color: #E5D3C3;
}

`, "",{"version":3,"sources":["webpack://./src/components/Header.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,aAAa;EACb,yBAAyB;EACzB,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,qBAAqB;EACrB,aAAa;EACb,SAAS;AACX;;AAEA;EACE,cAAc;EACd,qBAAqB;EACrB,gBAAgB;EAChB,sBAAsB;AACxB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* Header.css */\n.header {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  padding: 20px;\n  background-color: #333333;\n  color: #F5F5F5;\n}\n\n.logo {\n  font-size: 24px;\n  font-weight: bold;\n  color: #F5F5F5;\n}\n\n.nav-links {\n  list-style-type: none;\n  display: flex;\n  gap: 15px;\n}\n\n.nav-links li a {\n  color: #D3D3D3;\n  text-decoration: none;\n  font-weight: 500;\n  transition: color 0.3s;\n}\n\n.nav-links li a:hover {\n  color: #E5D3C3;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
