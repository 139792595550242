// src/components/Home.js
import React from 'react';
import './Body.css';

const Home = () => {
  return (
    <main className="home">
      <section className="intro">
        <h1>Welcome to Our Interior Design Studio</h1>
        <p>We bring your dream space to life with elegance and style.</p>
      </section>

      <section id="services" className="services">
        <h2>Our Services</h2>
        <p>Explore our range of interior design services.</p>
        {/* List services here */}
      </section>
    </main>
  );
};

export default Home;

