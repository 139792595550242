// src/App.js
import React from 'react';
import './App.css';
import Header from './components/HeaderComponent';
import Home from './components/BodyComponent';
import Gallery from './components/GalleryComponent';
import Contact from './components/ContactFormComponent';
import Footer from './components/FooterComponent';

const App = () => {
  return (
    <Container maxWidth="sm" className="App">

      <Header />
      <Home />
      <Gallery />
      <Contact />
      <Footer />
    </Container>
  );
};

export default App;

