// src/components/Gallery.js
import React from 'react';
import './Gallery.css';

const Gallery = () => {
  const images = ["pexels-fotios-photos-1090638.jpg","pexels-fotoaibe-1643383.jpg","pexels-jvdm-1457842.jpg" ,"pexels-pixabay-271816.jpg"]; // Sample image paths

  return (
    <section id="gallery" className="gallery">
      <h2>Our Gallery</h2>
      <div className="gallery-grid">
        {images.map((img, index) => (
          <img src={img} alt={`Gallery ${index + 1}`} key={index} className="gallery-image" />
        ))}
      </div>
    </section>
  );
};

export default Gallery;

