// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Home.css */
.home {
  text-align: center;
  padding: 40px 20px;
  background-color: #F5F5F5;
}

.intro h1 {
  font-size: 36px;
  color: #333333;
  margin-bottom: 10px;
}

.intro p {
  font-size: 18px;
  color: #666666;
  max-width: 600px;
  margin: 0 auto;
}

.services {
  margin-top: 50px;
  padding: 20px;
}

.services h2 {
  font-size: 30px;
  color: #333333;
}

.services p {
  font-size: 16px;
  color: #666666;
}

`, "",{"version":3,"sources":["webpack://./src/components/Body.css"],"names":[],"mappings":"AAAA,aAAa;AACb;EACE,kBAAkB;EAClB,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;EACd,gBAAgB;EAChB,cAAc;AAChB;;AAEA;EACE,gBAAgB;EAChB,aAAa;AACf;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB","sourcesContent":["/* Home.css */\n.home {\n  text-align: center;\n  padding: 40px 20px;\n  background-color: #F5F5F5;\n}\n\n.intro h1 {\n  font-size: 36px;\n  color: #333333;\n  margin-bottom: 10px;\n}\n\n.intro p {\n  font-size: 18px;\n  color: #666666;\n  max-width: 600px;\n  margin: 0 auto;\n}\n\n.services {\n  margin-top: 50px;\n  padding: 20px;\n}\n\n.services h2 {\n  font-size: 30px;\n  color: #333333;\n}\n\n.services p {\n  font-size: 16px;\n  color: #666666;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
