// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* App.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #F5F5F5; /* Set app-wide background color to off-white */
  color: #333333; /* Set default text color to charcoal */
}

header,
footer {
  flex-shrink: 0;
}

main {
  flex-grow: 1;
}

h1, h2, p {
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
    color: #E5D3C3; /* Link hover color changed to tan */
}

`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA,YAAY;AACZ;EACE,SAAS;EACT,UAAU;EACV,sBAAsB;EACtB,8BAA8B;AAChC;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAiB;EACjB,yBAAyB,EAAE,+CAA+C;EAC1E,cAAc,EAAE,uCAAuC;AACzD;;AAEA;;EAEE,cAAc;AAChB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,cAAc;AAChB;;AAEA;IACI,cAAc,EAAE,oCAAoC;AACxD","sourcesContent":["/* App.css */\n* {\n  margin: 0;\n  padding: 0;\n  box-sizing: border-box;\n  font-family: Arial, sans-serif;\n}\n\n.App {\n  display: flex;\n  flex-direction: column;\n  min-height: 100vh;\n  background-color: #F5F5F5; /* Set app-wide background color to off-white */\n  color: #333333; /* Set default text color to charcoal */\n}\n\nheader,\nfooter {\n  flex-shrink: 0;\n}\n\nmain {\n  flex-grow: 1;\n}\n\nh1, h2, p {\n  margin: 0;\n}\n\na {\n  text-decoration: none;\n  color: inherit;\n}\n\na:hover {\n    color: #E5D3C3; /* Link hover color changed to tan */\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
