// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Contact.css */
.contact {
  padding: 40px 20px;
  background-color: #F5F5F5;
  text-align: center;
  color: #333333; /* Text color set to charcoal */ 	
}

.contact h2 {
  font-size: 30px;
  color: #333333;
  margin-bottom: 20px;
}

.contact-form {
  max-width: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.contact-form label {
  text-align: left;
  color: #333;
  font-weight: 500;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  border: 1px solid #D3D3D3;
  border-radius: 5px;
  font-size: 16px;
}

.contact-form button {
  padding: 12px;
  background-color: #333333;
  color: #F5F5F5;
  border: none;
  border-radius: 5px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.contact-form button:hover {
  background-color: #E5D3C3;
}

`, "",{"version":3,"sources":["webpack://./src/components/ContactForm.css"],"names":[],"mappings":"AAAA,gBAAgB;AAChB;EACE,kBAAkB;EAClB,yBAAyB;EACzB,kBAAkB;EAClB,cAAc,EAAE,+BAA+B;AACjD;;AAEA;EACE,eAAe;EACf,cAAc;EACd,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,cAAc;EACd,aAAa;EACb,sBAAsB;EACtB,SAAS;AACX;;AAEA;EACE,gBAAgB;EAChB,WAAW;EACX,gBAAgB;AAClB;;AAEA;;EAEE,aAAa;EACb,yBAAyB;EACzB,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,yBAAyB;EACzB,cAAc;EACd,YAAY;EACZ,kBAAkB;EAClB,eAAe;EACf,eAAe;EACf,iCAAiC;AACnC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":["/* Contact.css */\n.contact {\n  padding: 40px 20px;\n  background-color: #F5F5F5;\n  text-align: center;\n  color: #333333; /* Text color set to charcoal */ \t\n}\n\n.contact h2 {\n  font-size: 30px;\n  color: #333333;\n  margin-bottom: 20px;\n}\n\n.contact-form {\n  max-width: 500px;\n  margin: 0 auto;\n  display: flex;\n  flex-direction: column;\n  gap: 15px;\n}\n\n.contact-form label {\n  text-align: left;\n  color: #333;\n  font-weight: 500;\n}\n\n.contact-form input,\n.contact-form textarea {\n  padding: 10px;\n  border: 1px solid #D3D3D3;\n  border-radius: 5px;\n  font-size: 16px;\n}\n\n.contact-form button {\n  padding: 12px;\n  background-color: #333333;\n  color: #F5F5F5;\n  border: none;\n  border-radius: 5px;\n  font-size: 18px;\n  cursor: pointer;\n  transition: background-color 0.3s;\n}\n\n.contact-form button:hover {\n  background-color: #E5D3C3;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
