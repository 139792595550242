// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Footer.css */
.footer {
  text-align: center;
  padding: 20px;
  background-color: #333333;
  color: #F5F5F5;
  font-size: 14px;
}

.social-links {
  margin-top: 10px;
}

.social-links a {
  color: #E5D3C3;
  margin: 0 8px;
  text-decoration: none;
  font-size: 18px;
}

.social-links a:hover {
  color: #F5F5F5;
}

`, "",{"version":3,"sources":["webpack://./src/components/Footer.css"],"names":[],"mappings":"AAAA,eAAe;AACf;EACE,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,cAAc;EACd,aAAa;EACb,qBAAqB;EACrB,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* Footer.css */\n.footer {\n  text-align: center;\n  padding: 20px;\n  background-color: #333333;\n  color: #F5F5F5;\n  font-size: 14px;\n}\n\n.social-links {\n  margin-top: 10px;\n}\n\n.social-links a {\n  color: #E5D3C3;\n  margin: 0 8px;\n  text-decoration: none;\n  font-size: 18px;\n}\n\n.social-links a:hover {\n  color: #F5F5F5;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
